import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import { Link, useLocation } from 'react-router-dom';
import ApiHelper from '../../helper/ApiHelper';
import NewsAndEventsServices from '../../services/NewsAndEvents.service';
import { Loader } from './Loader';

import './News.css';

const NewsAndEvents = () => {
    //set the news event data  and call the useState hooks
    const [allnewsandeventData, setNews_and_event] = useState([])
    const [NewsEventdata, setData] = useState([]);
    const [loading, setLoading] = useState(true)
    //set the initial value of perpage
    const [perPage] = useState(5);
    //set the initial page count Value
    const [pageCount, setPageCount] = useState(0);
    //get the url slug and call the uselocation hooks
    const news_and_event_Slug = useLocation()
    //desturcting the t variable and call the usetranslation hooks
    const { t } = useTranslation()
    //making the url of news event slug
    const news_event_Slug = news_and_event_Slug.pathname.split('/')[1]

    ///call the api helper functions
    const ApiHelperData = ApiHelper.getCMCRCData()
    ///get the live Image api url
    const Live_API_URL = ApiHelperData.Live_Image_Url

    // call the useEffect hooks
    useEffect(() => {
        setLoading(true)
        //call the news services list api
        NewsAndEventsServices.getNews_and_Events(news_event_Slug).then((response) => {
            // setLoading(false)
            const allnewslistData = response.data
            setLoading(false)
            //call the set pagination fucntions
            //callt the news event data functions
            setNews_and_event(allnewslistData)
            const data = allnewslistData.slice(0, perPage);
            setPageCount(Math.ceil(allnewslistData.length / perPage));
            setData(data);
        })
        //set the news_event_Slug inside the subscript and call render 
        // the page rendering 
    }, [news_event_Slug])

    //call the event handler for page clicked
    const handlePageClick = (data) => {
        //===count the current page=============
        const currentpage = data.selected;
        //increament the current page value and multiply by the perpages
        const endRange = (currentpage + 1) * perPage;
        //decrement the value of end range and perpage
        const startRange = endRange - perPage
        //set the data according to your paginations 
        setData(allnewsandeventData.slice(startRange, endRange));
        //scroll in the top of the page
        window.scrollTo(0, 0);
    }


    return (
        <div>

            <section className='news-section-block'>
                <div className='news-and-events-loader'>
                    <div className='loder-block'>
                        {loading && <Loader />}
                    </div>
                </div>
                <div className='news-section-inner'>
                    <div className='container custom-container-news'>
                        <h1>{t('news_and_events')}</h1>
                        {NewsEventdata.length ? NewsEventdata.map((newseventData, index) => (
                            <div className='news-block-post' key={index}>
                                <div className='news-image-block-post'>
                                    <Link to={`/news-and-events/${newseventData?.attributes?.slug}`} >  <img src={Live_API_URL + newseventData.attributes?.Media?.data?.attributes.url} alt='images' /></Link>
                                </div>
                                <div className='news-details-info'>
                                    <Link to={`/news-and-events/${newseventData?.attributes?.slug}`} >  <div className='news-number'>{newseventData?.attributes.Title}</div></Link>
                                    <div className='news-info-text'><p>{newseventData?.attributes.Snippet}</p></div>
                                    <div className='news-learn-more'>
                                        <Link to={`/news-and-events/${newseventData?.attributes?.slug}`} >
                                            {t("details")}
                                        </Link>
                                    </div>
                                </div>
                            </div>

                        )) : null}

                        <div className='pagination-block'>
                            <div className='pagination-content-inner'>
                                <ul>
                                    <ReactPaginate
                                        nextLabel=<FaAngleRight />
                                        previousLabel=<FaAngleLeft />
                                        breakLabel={'...'}
                                        onPageChange={handlePageClick}
                                        pageCount={pageCount >= 1 ? pageCount : pageCount}
                                        marginPagesDisplayed={3}
                                        pageRangeDisplayed={3}
                                        containerClassName="pagination pagination-playerinformation"
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link'}
                                        previousClassName={'page-item'}
                                        previousLinkClassName={'page-link'}
                                        nextClassName={'page-item'}
                                        nextLinkClassName={'page-link'}
                                        breakClassName={'page-item'}
                                        breakLinkClassName={'page-link'}
                                        activeClassName={'active'}

                                    />

                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default NewsAndEvents