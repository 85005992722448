//get url
//defining the  live api url 

//Base Url 
const Live_API_URL = "https://cmcrc.dyndns.org/api/"

//Image Base Url
const Live_Image_Url = "http://hkcmcrc.com/"



// const Live_API_URL = process.env.REACT_APP_LOCAL_LIVE_URL
// const Live_Image_Url = process.env.REACT_APP_LOCAL_Image_URL

// console.log("Live_API_URL",Live_API_URL)
// console.log("Live_Image_Url",Live_Image_Url)

// call the get functions 
const getCMCRCData = () => {
       //==========Get Language =========
       var language_id = "en"
       //checking the conditions
       if (localStorage.getItem('lang')) {
        // then get the language
           language_id = localStorage.getItem('lang');
       }
      
       //=======Define Header Data============
       const userheadersData = {
             locale: language_id,
       }
       
       const getUserHeaderData = {
           Live_API_URL: Live_API_URL,
           userheadersData: userheadersData,
           locale: language_id,
           Live_Image_Url : Live_Image_Url
       }
       return getUserHeaderData;
}

//exporting the ApiHelper Services
const ApiHelper = {
    getCMCRCData
}
export default ApiHelper;
