import React, { useEffect, useState } from 'react';
import './Home.css';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useTranslation, withTranslation } from 'react-i18next';

import HomeServices from '../../services/Home.service';
import { Link } from 'react-router-dom';
import ApiHelper from '../../helper/ApiHelper';


const Home = () => {

    ///call the api helper functions
    const ApiHelperData = ApiHelper.getCMCRCData()
    ///get the live Image api url
    const Live_API_Images_URL = ApiHelperData.Live_Image_Url

    const [allSliderData, setSliderData] = useState([])
    const [allnewseventData, setNewsAndEventData] = useState([])
    const [allaboutusData, setAboutUsData] = useState([])
    const [allpartnershipData, setPartnerShipData] = useState([])
    const { t } = useTranslation()

    // console.log("allSliderData", allSliderData)

    useEffect(() => {

        HomeServices.getHomeApi().then((response) => {
            const allHomeData = response.data
            setSliderData(allHomeData.attributes.Slider)
            setNewsAndEventData(allHomeData.attributes.NewsandEvents)
            setAboutUsData(allHomeData.attributes.AboutUs)
            setPartnerShipData(allHomeData.attributes.Partnerships)

        })
    }, [])

    return (
        <div>

            <section className='full-page-block'>
                <div className='page-inner-block'>
                    {/*  ==================================slider section start========================== */}

                    <section className='slider-section-block'>
                        <div className='slider-section-inner'>
                            <div>
                                <Carousel autoPlay={true} infiniteLoop={true} interval={2000} transitionTime={500} showThumbs={false} showStatus={false} showIndicators={false}>
                                    {allSliderData.length ? allSliderData.map((sliderData, index) => (
                                        <div className='slider-image-block' key={index}>
                                            <img alt="" src={Live_API_Images_URL + sliderData?.Media?.data?.attributes?.url} />
                                            <div className='slider-content-blcok'>
                                                <div className='container'>
                                                    <div className='slider-content-inner' >
                                                        <h1>{sliderData?.Title}</h1>
                                                        <p>{sliderData?.Content}</p>
                                                        <div className='slider-button-block'>
                                                            {sliderData?.ActionButtonURL ? <Link to={sliderData?.ActionButtonURL}>{sliderData?.ActionButtonText}</Link> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )) : null}

                                </Carousel>

                            </div>


                            {/* <Carousel autoPlay={true} infiniteLoop={true} interval={500} showThumbs={false}>
                                {allSliderData ? allSliderData.map((sliderData, index) => (
                                    <div className='slider-image-block' key={index}>
                                        <img src={Live_API_Images_URL + sliderData?.Media?.data?.attributes?.url} alt="slider-images" />
                                        <div className='slider-content-blcok'>
                                            <div className='container'>
                                                <div className='slider-content-inner' >
                                                    <h1>{sliderData?.Title}</h1>
                                                    <p>{sliderData?.Content}</p>
                                                    <div className='slider-button-block'>
                                                        {sliderData?.ActionButtonURL ? <Link to={sliderData?.ActionButtonURL} target="_blank">{sliderData?.ActionButtonText}</Link> : null}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                )) : null}
                            </Carousel> */}

                        </div>
                    </section>
                    {/*  ==================================slider section end========================== */}


                    {/*  ==================================About Us section start ========================== */}

                    <section className='about-us-section-block'>
                        <div className='container'>
                            <div className='about-us-inner'>
                                <h2>{allaboutusData?.Title}</h2>
                                <div className='about-us-info-text'>
                                    <p className='news-info-text' contentEditable='false' dangerouslySetInnerHTML={{ __html: allaboutusData?.Content }} />
                                </div>
                                <div className='read-more-button-block'>
                                    {allaboutusData?.ActionButtonURL ? <Link to={allaboutusData?.ActionButtonURL} >{allaboutusData?.ActionButton}</Link> : null}
                                </div>
                            </div>
                        </div>
                    </section>

                    {/*  ==================================About Us section end ========================== */}

                    {/*  ==================================News event section start ========================== */}


                    <section className='news-section-block'>
                        <div className='news-section-inner-block'>
                            <span className='news-heading'><h2>{allnewseventData?.Title}</h2></span>
                            <div className='container'>
                                <div className='row'>
                                    {allnewseventData?.news_and_events?.data.length ? allnewseventData?.news_and_events.data.map((newsData, index) => (
                                        <div className='col-sm-12 col-lg-4' key={index}>
                                            <div className='news-block-inner'>
                                                <Link to={`news-and-events/${newsData?.attributes?.slug}`}>
                                                    <div className='news-image-block' style={{ backgroundImage: "url(" + Live_API_Images_URL + newsData?.attributes?.Media?.data?.attributes.url + ")" }}>



                                                        {/* <Link to={`news-and-events/${newsData?.attributes?.slug}`}>  <img src={Live_API_Images_URL + newsData?.attributes?.Media?.data?.attributes.url} alt="News-images" /></Link> */}
                                                    </div>
                                                </Link>
                                                <Link to={`news-and-events/${newsData?.attributes?.slug}`}>  <div className='news-number'>{newsData?.attributes?.Title}</div></Link>
                                                <div className='news-info-text'><p>{newsData?.attributes?.Snippet}</p></div>
                                                <div className='news-learn-more'>
                                                    <Link to={`news-and-events/${newsData?.attributes?.slug}`}>{t('details')}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    )) : null}

                                    {allnewseventData?.news_and_events?.data ?
                                        <div className='more-news-button-block'>
                                            <Link to='/news-and-events'>{t('load_more')}</Link>
                                        </div>
                                        : null}

                                </div>
                            </div>


                        </div>
                    </section>

                    {/*  ==================================News event section end ========================== */}

                    {/*  ==================================Partnerships section start ========================== */}

                    <section className='partnership-content-block'>
                        <div className='partnership-inner-block'>

                            <span className='partnerships-heading'><h2>{allpartnershipData?.Title}</h2></span>
                            <div className='container'>
                                <div className='row'>

                                    {allpartnershipData?.Media?.data.length ? allpartnershipData?.Media?.data.map((partnershipData, index) => (
                                        <div className='col-sm-4' key={index}>
                                            <div className='partners-inner-block' style={{ backgroundImage: "url(" + Live_API_Images_URL + partnershipData?.attributes?.url + ")" }}>

                                                {/* <img src={Live_API_Images_URL + partnershipData?.attributes?.url}></img> */}
                                            </div>
                                        </div>
                                    )) : null}

                                </div>
                            </div>

                        </div>
                    </section>

                </div>
            </section>

        </div>
    );
};

export default Home;